import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ExportJob } from '../all.types';

@Injectable()
export class ExportCsvService {
  private _baseUrl = `${environment.api.baseUrl}/export/`;

  constructor(private readonly http: HttpClient) {}

  public downloadCsvFile(data: Blob, fileName: string): void {
    const download = (url: string): void => {
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    };

    const addBomToCSV = (textData: string): Blob => {
      const bom = '\uFEFF';
      const csvDataWithBOM = bom + textData;

      return new Blob([csvDataWithBOM], { type: 'text/csv;charset=utf-8' });
    };

    const handleFileRead = (event: ProgressEvent<FileReader>): void => {
      const textData = (event.target as FileReader).result as string;
      const blob = addBomToCSV(textData);
      const url = window.URL.createObjectURL(blob);
      download(url);
    };

    const reader = new FileReader();
    reader.onload = handleFileRead;
    reader.readAsText(data, 'utf-8');
  }

  public getExportJob(jobId: number): Observable<ExportJob> {
    const url = `${this._baseUrl}${jobId}`;

    return this.http.get<ExportJob>(url, { withCredentials: true });
  }

  public fetchCsvFile(jobId: number): Observable<Blob> {
    const url = `${this._baseUrl}${jobId}/csv`;

    return this.http.get<Blob>(url, { withCredentials: true, responseType: 'blob' as 'json' });
  }
}
